import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const SecondPage = () => (
  <Layout>
    <Seo title="Quy định tổng hợp về doanh nghiệp" />
    <h1>Quy định tổng hợp về doanh nghiệp, hợp đồng và các vấn đề liên quan</h1>
<p>Dịch vụ thành lập công ty chất lượng, uy tín với giá cả hợp lý và vô vàn ưu đãi, quà tặng kèm theo. Đặc biệt, trong năm 2018, 2019, khi khách hàng sử dụng dịch vụ <a href="https://wikiluat.com/2020/06/22/thanh-lap-cong-ty-lap-rap-linh-kien-bong-den-can-yeu-cau-gi/">thành lập công ty</a> của chúng tôi sẽ được tặng ngay:</p>
<p><strong>- 01 Dấu chức danh tùy chọn:&nbsp;</strong>Giám đốc, Tổng Giám đốc, Chủ tịch <a href="https://wikiluat.com/2020/11/17/don-xin-rut-khoi-hoi-dong-quan-tri/">Hội đồng quản trị</a>, Kế toán trưởng, Trưởng phòng, ...</p>
<p><strong>- 01 Dấu nội bộ:&nbsp;</strong>Dấu Bản sao, Dấu thu tiền, Dấu chi tiền, Dấu bán hàng, Dấu chữ kỹ, ...</p>
<p><strong>- 01 Gói chăm sóc <a href="https://wikiluat.com/2020/07/28/nhan-dinh-ve-tinh-phap-ly-cua-hop-dong-lao-dong-6-thang/">pháp lý</a> Doanh nghiệp 01 năm sau thành lập:&nbsp;</strong>Xử lý tất cả các vấn đề liên quan như Nhân sự, Bảo hiểm xã hội, Hợp đồng kinh tế, Chuyển đổi cơ cấu, ...</p>
<h2><strong>1. Thành lập công ty cổ phần có lợi thế gì</strong></h2>
<p>Xin chào anh chị, bọn em có dự định mong muốn <a href="https://wikiluat.com/2020/06/22/thanh-lap-cong-ty-lap-rap-linh-kien-bong-den-can-yeu-cau-gi/">thành lập công ty</a> cổ phần tại Cần Thơ, hoạt động trong lĩnh vực đào tạo ngoại ngữ và <a href="https://wikiluat.com/2021/08/16/hop-dong-dich-vu-tu-van-du-hoc/">tư vấn du học</a>, theo bọn em tìm hiểu thì hiện tại việt nam có khá nhiều các loại hình công ty được phép thành lập, nhưng bọn em không biết điểm lợi thế của riêng những loại hình này là gì khi so sánh với nhau, các hoạt động về tài chính, báo cáo thuế và góp vốn có gì cần lưu ý cho từng loại <a href="https://wikiluat.com/2021/09/01/don-xin-gia-han-hop-dong-thue-van-phong-cong-ty/">công ty</a>. Rất mong anh chị có sự am hiểu về <a href="https://wikiluat.com/2021/08/31/hop-dong-thue-giam-doc/">pháp luật</a> giúp bọn em phân tích và nêu ưu điểm của loại công ty cổ phần khi lĩnh vực hoạt động của bọn em như thế.</p>
<h2> <strong>2. Thành lập công ty TNHH cần tối thiếu bao nhiêu thành viên</strong></h2>
<p>Em xin hỏi câu này nhờ được mọi người tư vấn. Em muốn thành lập công ty để kinh doanh cho <a href="https://wikiluat.com/2021/08/01/cach-ghi-lo-de-hop-phap/">hợp pháp</a>, công ty là loại hình gia đình, gồm có em, ba em, chú em và một người bạn của ba cùng làm chung, hoạt động chủ yếu là nhập lại xe và các linh kiện của xe để kinh doanh, lắp đặt vào xe khách khi khách có yêu cầu. Em muốn hỏi là giờ em <a href="https://wikiluat.com/2020/06/21/muon-thanh-lap-cong-ty-tnhh-can-co-toi-thieu-bao-nhieu-nguoi/">thành lập công ty</a> tnhh thì thế đã đủ người chưa, cần thêm hay bớt gì không ạ?</p>
<p><strong>Căn cứ pháp lý:</strong></p>
<p>Luật Doanh nghiệp 2014;</p>
<p><strong>Trả lời:</strong></p>
<p>Hiện nay, theo <a href="https://wikiluat.com/2020/11/17/don-to-cao-ve-viec-doanh-nghiep-lam-sai-phap-luat/">pháp luật doanh nghiệp</a> hiện hành, cá nhân, tổ chức đăng ký thành lập doanh nghiệp có thể lựa chọn giữa một trong 4 hình thức doanh nghiệp là doanh nghiệp tư nhân, công ty hợp danh, công ty trách nhiệm hữu hạn và công ty cổ phần, trong đó, công ty trách nhiệm hữu hạn có hai loại dựa trên số thành viên góp vốn là công ty trách nhiệm hữu hạn một thành viên và công ty trách nhiệm hữu hạn hai thành viên trở lên.</p>
<p>Với hoạt động kinh doanh mà bạn dự tính thực hiện và dựa trên những người <a href="https://wikiluat.com/2021/08/17/hop-dong-thue-nha-de-kinh-doanh/">góp vốn vào doanh nghiệp</a> nêu trên, gia đình bạn đã đủ điều kiện để thành lập công ty trách nhiệm hữu hạn theo mong muốn. Tuy nhiên, bạn và gia đình nên lựa chọn loại hình doanh nghiệp thành lập giữa công ty cổ phần hoặc công ty trách nhiệm hữu hạn hai thành viên trở lên bởi hai loại hình doanh nghiệp này, bạn và gia đình đều đủ điều kiện số thành viên, người góp vốn theo yêu cầu thành lập của hai loại công ty trên, Cụ thể hai loại hình doanh nghiệp nêu trên có những đặc điểm cơ bản sau:</p>
<li>Công ty cổ phần là doanh nghiệp, trong đó:</li>
<p>- Vốn điều lệ được chia thành nhiều phần bằng nhau gọi là cổ phần;</p>
<p>- Cổ đông có thể là tổ chức, cá nhân; số lượng cổ đông <strong>tối thiểu là 03</strong> và <strong>không hạn chế</strong> số lượng tối đa;</p>
<p>- Cổ đông chỉ chịu trách nhiệm về các khoản nợ và nghĩa vụ tài sản khác của doanh nghiệp trong phạm vi số vốn đã góp vào doanh nghiệp;</p>
<p>- Cổ đông có quyền tự do <a href="https://wikiluat.com/2021/08/04/hop-dong-chuyen-nhuong-co-phan-dang-so-chung-khoan-dien-tu-tren-san-giao-dich/">chuyển nhượng cổ phần</a> của mình cho người khác, trừ trường hợp do pháp luật quy định hạn chế đối với cổ đông sáng lập trong thời hạn 03 năm, kể từ ngày công ty được cấp Giấy chứng nhận đăng ký doanh nghiệp.</p>
<p>Công ty cổ phần có tư cách pháp nhân kể từ ngày được cấp Giấy chứng nhận đăng ký doanh nghiệp và có quyền phát hành cổ phần các loại để huy động vốn. <strong>(Điều 110 Luật Doanh nghiệp 2014)</strong></p>
<li>Công ty trách nhiệm hữu hạn hai thành viên trở lên là doanh nghiệp đó:</li>
<p>- Thành viên có thể là tổ chức, cá nhân; số lượng thành viên <strong>không vượt quá 50</strong>;</p>
<p>- Thành viên chịu trách nhiệm về các khoản nợ và nghĩa vụ tài sản khác của doanh nghiệp&nbsp;trong phạm vi số vốn đã góp vào doanh nghiệp;</p>
<p>- Phần vốn góp của thành viên chỉ được chuyển nhượng theo <a href="https://wikiluat.com/2021/09/01/don-kien-nghi-doi-giao-vien/">quy định</a>;</p>
<p>Công ty trách nhiệm hữu hạn hai thành viên trở lên có tư cách pháp nhân kể từ ngày được cấp Giấy chứng nhận đăng ký doanh nghiệp và không được quyền phát hành cổ phần để huy động vốn<strong> (Điều 47 Luật Doanh nghiệp 2014).</strong></p>
<p>Theo đó, mặc dù gia đình bạn có định hướng thành lập công ty trách nhiệm hữu hạn hai thành viên thì dựa trên những đặc điệm pháp lý cơ bản nêu trên của hai loại hình doanh nghiệp, chúng tôi cho rằng, bạn và gia đình nên đăng ký thành lập loại hình công ty cổ phần để có thể thuận tiện mở rộng và phát triển <a href="https://wikiluat.com/2020/07/30/don-xin-hoat-dong-kinh-doanh-cho-thue-nha/">hoạt động kinh doanh</a> sau này.</p>

   <li><a href="https://wikiluat.com/2021/08/29/don-khieu-nai-ve-hanh-vi-gay-o-nhiem-moi-truong/">Đơn khiếu nại về hành vi gây ô nhiễm môi trường</a></li><li><a href="https://wikiluat.com/2021/08/29/don-xin-tang-gia/">Đơn xin tăng giá – Đơn đề nghị tăng giá</a></li><li><a href="https://wikiluat.com/2021/08/29/mau-don-yeu-cau-do-dac-dat/">Đơn yêu cầu đo đạc đất, tư vấn xác định ranh giới mốc giới</a></li><li><a href="https://wikiluat.com/2021/08/29/don-de-nghi-di-doi-cot-dien/">Đơn đề nghị di dời cột điện – Đơn xin di dời cột điện</a></li>
  
  </Layout>
)

export default SecondPage
